import result from "./result";
import untilEntrepreneurship from "./until-Entrepreneurship";
import LifeScience from "./Life-Science";
import Mathematics from "./Mathematics";

export default [
    ...LifeScience,
    ...Mathematics,
    ...untilEntrepreneurship,
    ...result,
];